import React from "react";
import styles from "./ProjectPresentation.module.scss";

import ScrollToTop from "react-scroll-up";
import TextLoop from "react-text-loop";
import FooterTwo from "../component/footer/FooterTwo";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/HeaderFour";
import TabThree from "../elements/tab/TabThree";
import Helmet from "../component/common/Helmet";
import PageHelmet from "../component/common/Helmet";
import ReactGA from "react-ga4";
const ProjectPresentation = () => {
  const SlideList = [
    {
      textPosition: "text-center",
      category: "",
      title: "PROIECTELE MELE",
      description:
        "Un lucru este sigur, pun suflet în toate proiectele din care fac parte și vreau să mă asigur că întrunesc cerințele clienților mei.",
      buttonText: "Contact",
      buttonLink: "/contact"
    }
  ];

  return (
    <React.Fragment>
      <PageHelmet pageTitle={"Proiecte"} />
      <Header headerPosition="header--static" logo="symbol-dark" color="color-black" />

      <div className="slider-wrapper">
        {/* Start Single Slide */}
        {SlideList.map((value, index) => (
          <div
            className="slide slide-style-2 fullscreen d-flex align-items-center justify-content-center bg_image bg_image--24"
            key={index}
            data-black-overlay="6"
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className={`inner ${value.textPosition}`}>
                    {value.category ? <span>{value.category}</span> : ""}
                    {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ""}
                    {value.description ? <p className="description">{value.description}</p> : ""}
                    {value.buttonText ? (
                      <div className="slide-btn">
                        <a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>
                          {value.buttonText}
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single Slide */}
      </div>

      {/* Start Designer Portfolio  */}
      <div id="projects" className="designer-portfolio-area ptb--120 bg_color--1">
        <div className="wrapper plr--70 plr_sm--30 plr_md--30">
          <TabThree column="col-lg-4 col-md-6 col-sm-6 col-12" />
        </div>
      </div>
      {/* End Designer Portfolio  */}
    </React.Fragment>
  );
};

export default ProjectPresentation;
