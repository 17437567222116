import React from "react";
import { Helmet } from "react-helmet";

const PageHelmet = (props) => {
  return (
    <React.Fragment>
      <Helmet>
        <title>{props.pageTitle} || Foto Video Editor </title>
        <meta name="description" content="Servicii video si fotografie in Cluj-Napoca, Turda si Campia Turzii" />
        <meta property="og:title" />
        <meta name="image" property="og:image" content={props.imageOG} />
      </Helmet>
    </React.Fragment>
  );
};

export default PageHelmet;
