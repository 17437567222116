import React, { Component } from "react";
import parse from "html-react-parser";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import myStrings from "./../../customString/myStrings.json";

const SocialShare = [
  { Social: <FaFacebookF />, link: "https://www.facebook.com/dani.anca.7" },
  { Social: <FaLinkedinIn />, link: "https://www.linkedin.com/in/danielanca" },
  { Social: <FaInstagram />, link: "https://www.instagram.com/danianca.ro" }
];

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer-area">
          <div className="footer-wrapper">
            <div className="row align-items-end row--0">
              <div className="col-lg-6">
                <div className="footer-left">
                  <div className="inner">
                    <span>LET'S DO THIS</span>
                    <h2>{parse("Hai sa vorbim <br /> despre proiectul <br /> tau 🤓 ")}</h2>
                    <a className="rn-button-style--2" href="/contact">
                      <span>Contact</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="footer-right" data-black-overlay="6">
                  <div className="row">
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12">
                      <div className="footer-link">
                        <h4>Linkuri utile</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="/proiecte">Proiecte</a>
                          </li>
                          <li>
                            <a href="/contact">Contact</a>
                          </li>
                          <li>
                            <a href="/">Home</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* End Single Widget  */}
                    {/* Start Single Widget  */}
                    <div className="col-lg-6 col-sm-6 col-12 mt_mobile--30">
                      <div className="footer-link">
                        <h4>Putem vorbi aici</h4>
                        <ul className="ft-link">
                          <li>
                            <a href="mailto:ancadaniel1994@gmail.com">ancadaniel1994@gmail.com</a>
                          </li>
                        </ul>

                        <div className="social-share-inner">
                          <ul className="social-share social-style--2 d-flex justify-content-start liststyle mt--15">
                            {SocialShare.map((val, i) => (
                              <li key={i}>
                                <a href={`${val.link}`}>{val.Social}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* End Single Widget  */}

                    <div className="col-lg-12">
                      <div className="copyright-text">
                        <p>{myStrings.RO.footer.footerText}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
export default Footer;
