export const templateOffer = `<div>
    <h2 class="text-center"> Eu și pisica! </h2>
    <h3 class="text-center"> Prețuri de la  <span class="attentionPrice"> 250 RON <span> </h3>

    <div className="section-title service-style--3 text-center mb--25">
        <p class="section-title.service-style--3 p"> În funcție de bugetul ales, ne concentrăm împreună să eficientizăm metoda de implementare și să oferim rezultatele <b> la timp </b>  </p>   
        <p> <b>Alte proiecte la care lucrez(Click) </b></p>
        <ul style="list-style:none; padding:0"> 
           <a  href="https://casacaly.ro"  target="_blank" > <li style="font-weight:700; color: blue;" >CasaCaly.Ro</li> </a>
           <a href="https://oasisresidence.co" target="_blank"> <li style="font-weight:700; color: #C8A382;">OasisResidence.co</li> </a>
           <a href="https://brasov.danianca.ro" target="_blank"> <li style="font-weight:700; color:#FF1493 ">Brasov Romantic Studio (In lucru)</li> </a>
           <a href="https://montanair.ro" target="_blank"> <li style="font-weight:700">MontanAir.Ro (in lucru)</li> </a>
        </ul>
        <div class="socialContainer">
            <a href="https://www.facebook.com/dani.anca.7"><img style="width:40px;  height:40px; margin-left:8px; margin-right:8px;  " src="https://firebasestorage.googleapis.com/v0/b/daniancaro-23015.appspot.com/o/DaniAncaRo%2Ffacebook.png?alt=media&token=00ba077f-55d5-4181-80a5-f83ea2720ebf"> </a>
            <a href="https://instagram.com/danianca94"><img style="width:40px;  height:40px; margin-left:8px; margin-right:8px;" src="https://firebasestorage.googleapis.com/v0/b/daniancaro-23015.appspot.com/o/DaniAncaRo%2Finstagram.png?alt=media&token=20a800d2-ffe1-4a37-9e02-aa5ebcd13c47"></a>
        </div>
        </div>

   
</div>`;
export const detailContainer = `
    <h3 class="text-center">Anca Daniel Emanuel</h3> 
     <h4 class="text-center" >Persoana Fizica Autorizata</h4> 
     <p class="text-center">CUI: 4504447</p>
     <p></p>
    
`;
