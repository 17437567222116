import React, { Component } from "react";
import ContactForm from "./ContactForm";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">{"Contact"}</h2>
                <p className="description">{"Lasa un mesaj si revin eu repede-repede!"}</p>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="thumbnail mb_md--30 mb_sm--30">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/casacaly%2FDani%2Fdani%20comp%202.jpg?alt=media&token=d1dd3c22-114b-4b98-a908-76f86a4c8340"
                  alt="trydo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
