import React, { Component } from "react";
import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import ScrollTo from "react-scroll-into-view";
import { HashLink, NavHashLink, NavLink } from "react-router-hash-link";
class HeaderFour extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }
  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }
  render() {
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement.querySelector(".submenu").classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }

    const { logo, color, headerPosition } = this.props;

    return (
      <header style={{ padding: "0 6%" }} className={`header-area formobile-menu ${headerPosition} ${color}`}>
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              {/* <a href="/">
                                {logoUrl}
                            </a> */}
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <ul className="mainmenu">
                <li>
                  <NavHashLink to="/"> {"Acasa"}</NavHashLink>
                </li>
                <li>
                  <NavHashLink smooth={true} to="/proiecte">
                    {"Proiecte"}
                  </NavHashLink>
                </li>
                <li className="onlyOnTabletMobile">
                  <NavHashLink to="/contact">{"Contact"}</NavHashLink>
                </li>
                {/* <li>
                  <Link to="/about">About</Link>
                </li> */}

                {/* <li className="has-droupdown">
                  <Link to="#pages">Pages</Link>
                  <ul className="submenu">
                    <li>
                      <Link to="/blog">Blog List</Link>
                    </li>
                    <li>
                      <Link to="/blog-details">Blog Details</Link>
                    </li>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/service-details">Service Details</Link>
                    </li>
                    <li>
                      <Link to="/portfolio">Portfolio</Link>
                    </li>
                    <li>
                      <Link to="/portfolio-details">Portfolio Details</Link>
                    </li>
                    <li>
                      <Link to="/404">404</Link>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </nav>

            <NavHashLink className={"onlyOnDesktop"} to="/contact">
              <div className="header-btn ">
                <a className="rn-btn"> {"Contact"}</a>
              </div>
            </NavHashLink>

            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span onClick={this.menuTrigger} className="menutrigger text-white">
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default HeaderFour;
