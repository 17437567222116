import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-image-lightbox/style.css";
import ReactGA from "react-ga4";
import { getPortofolioJSON } from "../../utils/portfolioData";
import styles from "./styles.module.scss";
ReactGA.initialize("G-BV4CKQRCZC");

//best resolution for thumbnail is 1315 x 800
const column = "col-lg-4 col-md-6 col-sm-6 col-12";

const filterBy = (objData, keyFilter) => {
  return Object.values(objData.RO).filter((item) => item.category.includes(keyFilter));
};
const doubleFilter = (objData, keyFilter, keyFilterTwo) => {
  return Object.values(objData.RO).filter(
    (item) => item.category.includes(keyFilter) || item.category.includes(keyFilterTwo)
  );
};

const TabThree = () => {
  const [dataPortofolio, setDataPortofolio] = useState(null);
  const [TabOne, setTabOne] = useState(null);
  const [TabTwo, setTabTwo] = useState(null);
  const [TabThree, setTabThree] = useState(null);

  useEffect(() => {
    getPortofolioJSON().then((dataBe) => {
      setDataPortofolio(dataBe);
    });
  }, []);

  useEffect(() => {
    if (dataPortofolio != null) {
      setTabOne(doubleFilter(dataPortofolio, "Foto", "Video"));
      setTabTwo(filterBy(dataPortofolio, "Foto"));
      setTabThree(filterBy(dataPortofolio, "Video"));
    }
  }, [dataPortofolio]);

  const videoClick = () => {
    ReactGA.send("Video Tab ClickEvent");
  };
  const photoClick = () => {
    ReactGA.send("Web Tab ClickEvent");
  };
  const allClick = () => {
    ReactGA.send("All Tab ClickEvent");
  };
  const eventClick = (event) => {
    ReactGA.event("Clicked:" + event);
    console.log("Clciked", event);
  };

  return (
    <div>
      <Tabs>
        <div className="row text-center">
          <div className="col-lg-12">
            <p className={styles.selectProject}>{"Selectează categoria de proiecte"}</p>
            <div className="tablist-inner">
              <TabList className="pv-tab-button text-center mt--0">
                <Tab>
                  <span onClick={allClick}>😃Toate</span>
                </Tab>
                <Tab>
                  <span onClick={photoClick}>📸Proiecte Foto</span>
                </Tab>
                <Tab>
                  <span onClick={videoClick}>🎬Proiecte Video</span>
                </Tab>
              </TabList>
            </div>
          </div>
        </div>

        <TabPanel className="row row--35">
          {TabOne &&
            TabOne.map((value, index) => (
              <div className={`${column}`} key={index}>
                <div className="item-portfolio-static">
                  <div>
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <div
                          onClick={eventClick.bind(1, value.title)}
                          className={"thumbnail " + styles.thumbnailCustom}
                        >
                          <a href={`/proiect/${value.link}`}>
                            <img src={value.image} alt={value.title} className={styles.imageThumbnail} />
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p className="bolderCategory">{value.category}</p>
                          <h4>
                            <a href={`/proiect/${value.link}`}>{value.title}</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </TabPanel>

        <TabPanel className="row row--35">
          {TabTwo &&
            TabTwo.map((value, index) => (
              <div className={`${column}`} key={index}>
                <div className="item-portfolio-static">
                  <div>
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <div
                          onClick={eventClick.bind(1, value.title)}
                          className={"thumbnail " + styles.thumbnailCustom}
                        >
                          <a href={`/proiect/${value.link}`}>
                            <img className={styles.imageThumbnail} src={value.image} alt={value.title} />
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p className="bolderCategory">{value.category}</p>
                          <h4>
                            <a href={`/proiect/${value.link}`}>{value.title}</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </TabPanel>

        <TabPanel className="row row--35">
          {TabThree &&
            TabThree.map((value, index) => (
              <div className={`${column}`} key={index}>
                <div className="item-portfolio-static">
                  <div>
                    <div className="portfolio-static">
                      <div className="thumbnail-inner">
                        <div
                          onClick={eventClick.bind(1, value.title)}
                          className={"thumbnail " + styles.thumbnailCustom}
                        >
                          <a href={`/proiect/${value.link}`}>
                            <img className={styles.imageThumbnail} src={value.image} alt={value.title} />
                          </a>
                        </div>
                      </div>
                      <div className="content">
                        <div className="inner">
                          <p className="bolderCategory">{value.category}</p>
                          <h4>
                            <a href={`/proiect/${value.link}`}>{value.title}</a>
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default TabThree;
