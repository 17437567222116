import React, { Component, useEffect, useMemo, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import BrandTwo from "./BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import offerSF from "../customString/offerSF.json";
import { templateOffer, detailContainer } from "../customString/Offer";
import ModalVideo from "react-modal-video";
import ReactGA from "react-ga4";
import ContentEditor from "./ContentEditor";
import jsxToString from "jsx-to-string";
import parse from "html-react-parser";
import useWindowDimensions from "../utils/getWindowDimensions";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import pdfFile from "./../data/DaniAnca_CV.pdf";
import styles from "./../elements/MyCV.module.scss";
import image from "./../data/onedrive-1.svg";

const MyCV = () => {
  const { width: webpageWidth } = useWindowDimensions();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [file, setFile] = useState(pdfFile);
  ReactGA.send("Location path:", window.location.pathname);
  ReactGA.send("CV Page visited");
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    console.log("Page numbers are:", numPages);
  }
  console.log("webpage:", webpageWidth);

  const gotoLink = () => {
    window.open("https://1drv.ms/u/s!Ame2DACZNorQjO91W8WjDtjI1Ctmtw?e=40YJ9f");
  };
  return (
    <React.Fragment>
      <PageHelmet
        imageOG="https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/dani%2Fmobilefriendly.png?alt=media&token=5c5676ff-3edc-4cbe-8da1-64e10a7e3504"
        pageTitle="About"
      />
      <p className={styles.centerPageNumbers}>
        Page {pageNumber} of {numPages}
      </p>
      <div className={styles.buttonActionWrapper}>
        <button onClick={gotoLink} className={styles.downloadButton}>
          <img className={styles.oneDriveIcon} src={image} />
          <p>{"Download CV"}</p>
        </button>
        <p>{"Thank you for your interest in my CV. You can download it from OneDrive or view it below."}</p>
      </div>
      <div className="rn-section ptb--40 bg_color--1 line-separator">
        <div className="container">
          <div className="row sercice-details-content align-items-center justify-content-center">
            <div className={styles.pdfWrapper}>
              <Document
                file={pdfFile}
                onLoadSuccess={onDocumentLoadSuccess}
                error={<p>{"Thanks for passing by! -Daniel"}</p>}
              >
                {Array.from(
                  new Array(numPages),
                  (el, index) =>
                    (index !== 0 && webpageWidth > 576 && (
                      <Page width={webpageWidth * 0.63} className={styles.pagePdf} pageNumber={index} />
                    )) ||
                    (index !== 0 && webpageWidth < 576 && (
                      <Page width={webpageWidth * 0.9} className={styles.pagePdf} pageNumber={index} />
                    ))
                )}
              </Document>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MyCV;
