import React, { useState } from "react";
import emailjs from "emailjs-com";

const Result = () => {
  return <p className="success-message">{"Mesajul tau a fost trimis. Te voi contacta in scurt timp. Zi frumoasa!"}</p>;
};
function ContactForm({ props }) {
  const [result, showresult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm("service_p4x3hv8", "template_jgfr42f", e.target, "user_jrfTH2e0Ely35ZCVFdT9S").then(
      (result) => {
        console.log(result.text);
      },
      (error) => {
        console.log(error.text);
      }
    );
    e.target.reset();
    showresult(true);
  };

  setTimeout(() => {
    showresult(false);
  }, 8000);

  return (
    <form action="" onSubmit={sendEmail}>
      <div className="rn-form-group">
        <input type="text" name="fullname" placeholder="Nume" required />
      </div>

      <div className="rn-form-group">
        <input type="email" name="email" placeholder="Email" required />
      </div>

      <div className="rn-form-group">
        <input type="text" name="phone" placeholder="Telefon" required />
      </div>

      <div className="rn-form-group">
        <textarea name="message" placeholder="Mesaj" required></textarea>
      </div>

      <div className="rn-form-group">
        <button
          className="rn-button-style--2 btn-solid"
          type="submit"
          value="submit"
          name="submit"
          id="mc-embedded-subscribe"
        >
          Trimite
        </button>
      </div>

      <div className="rn-form-group">{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactForm;
