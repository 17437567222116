import React, { Component, useEffect, useMemo, useState } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
import CounterOne from "./counters/CounterOne";
import Testimonial from "./Testimonial";
import BrandTwo from "./BrandTwo";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import { createEditor } from "slate";
import { Slate, Editable, withReact } from "slate-react";
import offerSF from "../customString/offerSF.json";
import { templateOffer, detailContainer } from "./../customString/Offer";
import ModalVideo from "react-modal-video";
import ReactGA from "react-ga4";
import ContentEditor from "./ContentEditor";
import jsxToString from "jsx-to-string";
import parse from "html-react-parser";

ReactGA.initialize("G-BV4CKQRCZC");

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "A line of text in a paragraph." }]
  }
];
const SFOffer = () => {
  ReactGA.send("Locatino path:", window.location.pathname);
  ReactGA.send("SFO  Visiting");
  var title = "Pensiune Oferta";
  var description = "Oferta personalizata pensiune";
  const [modalVideoVisible, setModalVideo] = useState(false);

  const modalVideoHandler = () => {
    setModalVideo(!modalVideoVisible);
  };
  const [isButtonOn, setButtonOn] = useState(true);
  const [editor] = useState(() => withReact(createEditor()));
  const editorHand = useMemo(() => withReact(createEditor()), []);
  useEffect(() => {
    if (modalVideoVisible) {
      ReactGA.event({ category: "Social", action: "User clicked on the SFO Video" });
    }
  }, [modalVideoVisible]);

  const backToHome = () => {
    ReactGA.event({ category: "Social", action: "Guest clicked from SFO to Home" });
    document.location.href = "/";
  };
  return (
    <React.Fragment>
      <PageHelmet
        imageOG="https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/dani%2Fmobilefriendly.png?alt=media&token=5c5676ff-3edc-4cbe-8da1-64e10a7e3504"
        pageTitle="About"
      />

      {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
      <a onClick={backToHome} class="rn-button-style--2 btn-solid mt-2 mx-auto userNoSelect cursorPointer mx-auto">
        <span>Portofoliu Meu</span>
      </a>
      <div className="rn-section ptb--40 bg_color--1 line-separator">
        <div className="container">
          <div className="row sercice-details-content align-items-center justify-content-center">
            <h2 className="text-center">Iti facem site-ul</h2>
            <div className="col-lg-12 ">
              <div className="thumb position-relative dflex justify-center">
                <img
                  style={{ minWidth: "350px" }}
                  className="popUpDani w-50"
                  src="https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/dani%2FpisicaThumbnailMare.jpg?alt=media&token=245995c2-2096-43df-b4f6-b8fe183763d9"
                  alt="Service Images"
                />
                <ModalVideo
                  channel="youtube"
                  isOpen={modalVideoVisible}
                  videoId="ztZo1aycVtM"
                  onClose={modalVideoHandler}
                />
                <button className="video-popup position-top-center specialStyle" onClick={modalVideoHandler}>
                  <span className="play-icon"></span>
                </button>
              </div>
            </div>
            {/* <ContentEditor></ContentEditor> */}

            <div>{parse(templateOffer)}</div>
          </div>
        </div>
      </div>

      {/* Start Team Area  */}
      <div className="rn-team-area bg_color--1 ptb--40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--25">
                <h2 className="title">Site-ul tau</h2>
                <p>
                  Te invit <b>sa vizualizezi video-ul </b> in care explic un pic despre importanta unui website, ce
                  arata bine <b>nu doar pe laptop, ci si pe telefonul mobil sau tableta</b>, un lucru asa de necesar in
                  zilele noastre
                </p>
                <img
                  className={"mobileFriendly"}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/sapunmontan.appspot.com/o/dani%2Fmobilefriendly.png?alt=media&token=5c5676ff-3edc-4cbe-8da1-64e10a7e3504"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
      {/* End Team Area  */}
      <div className="rn-team-area bg_color--1 ptb--40">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title service-style--3 text-center mb--25">
                <h2 className="title">{"Împreună"}</h2>
                <p>
                  Un proiect nu are succes deplin, doar <b>dacă cei din echipă lucrează impreună.</b> Vom ține periodic
                  legătura despre <b>evolutia proiectului</b>, și vom interveni cu întrebări in caz de nelămuriri. "
                </p>
                <img
                  className={"mobileFriendly"}
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/daniancaro-23015.appspot.com/o/DaniAncaRo%2Ftogether.png?alt=media&token=c383a103-8021-42b1-a263-7df14332acbe"
                  }
                />
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
      </div>
      {/* Start About Area  */}
      <div className="rn-about-area ptb--10 bg_color--1">
        <div className="rn-about-wrapper">
          <div className="container">
            <div className="row row--35 align-items-center">
              <div className="col-lg-12">
                <div className="about-inner inner">
                  <div className="row mt--30">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title text-center">Organizare</h3>
                        <p style={{ minWidth: "283px" }} className="text-center w-50 mr-auto ml-auto">
                          <b>Planuim proiectul</b> in etape, de la momentul 0 pana la <b>"Este gata!"</b>
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="about-us-list">
                        <h3 className="title text-center">Sfatuire</h3>
                        <p style={{ minWidth: "283px" }} className="text-center w-50 mr-auto ml-auto">
                          <b>Primim si oferim sfaturi</b>, deoarece un proiect <b>iese bine atunci </b>cand ambele parti
                          contribuie activ la realizarea lui.
                        </p>
                      </div>
                    </div>

                    {/* <a
                      onClick={detailsClickHandler}
                      class="rn-button-style--2 btn-solid mt-2 mx-auto userNoSelect cursorPointer"
                    >
                      <span>Click Detalii</span>
                    </a> */}
                    <div className="emptyContainer">{isButtonOn ? <>{parse(detailContainer)}</> : ``}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End About Area  */}

      {/* Start Testimonial Area */}
      {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
        <div className="container">
          <Testimonial />
        </div>
      </div> */}
      {/* End Testimonial Area */}

      {/* Start Brand Area */}

      {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
        <h3 className="title text-center">Parteneri & Clienti</h3>
        <p className="title text-center">Proiecte in care am fost implicat, Website sau Video </p>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <BrandTwo />
            </div>
          </div>
        </div>
      </div> */}
      {/* End Brand Area */}

      {/* Start Back To Top */}
      {/* <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div> */}
      {/* End Back To Top */}

      <Footer />
    </React.Fragment>
  );
};

// class SFOffer extends Component {
//   render() {
//     let title = "About",
//       description =
//         "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.";
//     return (

export default SFOffer;
